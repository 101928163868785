import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://crm-dev-alb-1296188767.ap-southeast-1.elb.amazonaws.com';

// Styled Components
const StyledCard = styled(Card)`
  max-width: 75rem;
  margin: 7rem auto 0;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
`;

const AccountItem = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const StatusBadge = styled.span`
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${(props) => {
    switch (props.$status.toLowerCase()) {
      case "active":
        return "#dcfce7";
      case "inactive":
        return "#fee2e2";
      case "pending":
        return "#fef9c3";
      default:
        return "#f3f4f6";
    }
  }};
  color: ${(props) => {
    switch (props.$status.toLowerCase()) {
      case "active":
        return "#166534";
      case "inactive":
        return "#991b1b";
      case "pending":
        return "#854d0e";
      default:
        return "#374151";
    }
  }};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const ManageUsers = () => {
  // State management
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState("create");
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationMessage, setVerificationMessage] = useState('');
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    accountId: '',
    clients: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: ''
  });

  // Load accounts on component mount
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/users`, {
          method: 'GET',
        })
        if (response.ok) {
          const data = await response.json();
          setAccounts(data);
          setFilteredAccounts(data);
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, []);

  // Search functionality
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterAccounts(value);
  };

  const filterAccounts = (value) => {
    const filtered = accounts.filter(
      (account) =>
        account.accountId.toLowerCase().includes(value.toLowerCase()) ||
        account.clients.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAccounts(filtered);
  };

  // Dialog handlers
  const handleOpenDialog = (mode, account = null) => {
    setDialogMode(mode);
    if (account) {
      setSelectedAccount(account);
      setFormData(account);
    } else {
      setSelectedAccount(null);
      setFormData({
        accountId: '',
        clients: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: ''
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAccount(null);
    setFormData({
      accountId: '',
      clients: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const createAccount = async (accountData) => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(accountData)
      });
  
      if (response.ok) {
        const data = await response.json();
  
        // Automatically trigger sending of verification email
        await sendVerificationEmail(accountData.email, accountData.password);
  
        // Prompt user to enter verification code
        setShowVerificationInput(true);
        setVerificationMessage('Account created successfully. Please check your email for the verification code.');
  
        return data;
      } else {
        console.error('Failed to create account');
        setError('Failed to create account');
        return null;
      }
    } catch (error) {
      console.error('Error creating account:', error);
      setError('An error occurred during account creation.');
      return null;
    }
  };

  // Function to send verification email
  const sendVerificationEmail = async (email, password) => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/users/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "email": email, "password": password, "verificationCode": 0 })
      });
  
      if (!response.ok) {
        console.error('Failed to send verification email');
        setMessage('Account created, but verification email could not be sent. Please contact support.');
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      setMessage('Account created, but an error occurred while sending the verification email.');
    }
  };
  
  // Function to verify user using the verification code
  const handleVerificationCodeSubmission = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/users/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: formData.email, "password": formData.password, "verificationCode": verificationCode })
      });
  
      if (response.ok) {
        setVerificationMessage('Your account has been verified successfully!');
        setShowVerificationInput(false); // Hide the input after successful verification
      } else {
        setVerificationMessage('Verification failed. Please check the code and try again.');
      }
    } catch (error) {
      console.error('Error during verification:', error);
      setVerificationMessage('An error occurred during verification. Please try again later.');
    }
  };

  const editAccount = async (accountData, updatedData) => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/users/${accountData.email}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          'id-token': `${localStorage.getItem("ID_TOKEN")}`,
          'refresh-token': `${localStorage.getItem("REFRESH_TOKEN")}`
        },
        body: JSON.stringify(updatedData)
      });
  
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error('Failed to update account');
        return null;
      }
    } catch (error) {
      console.error('Error updating account:', error);
      return null;
    }
  };
  
  const disableAccount = async (accountData) => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/users/${accountData.email}/disable`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        }
      });
  
      return response.ok;
    } catch (error) {
      console.error('Error disabling account:', error);
      return false;
    }
  };

  const handleSubmit = async () => {
    const currentDate = new Date().toISOString().split("T")[0];
  
    if (dialogMode === "create") {
      const newAccountData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        role: formData.role
      };
      const newAccount = await createAccount(newAccountData);
      if (newAccount) {
        console.log("User created:", newAccount);
        setAccounts((prev) => [...prev, newAccount]);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          role: ''
        });
        setFilteredAccounts((prev) => [...prev, newAccount]);
      }
    } else if (dialogMode === "edit" && selectedAccount) {
      const updatedAccount = await editAccount(selectedAccount, formData);
      if (updatedAccount) {
        setAccounts((prev) =>
          prev.map((account) =>
            account.AccountID === selectedAccount.AccountID ? updatedAccount : account
          )
        );
        setFilteredAccounts((prev) =>
          prev.map((account) =>
            account.AccountID === selectedAccount.AccountID ? updatedAccount : account
          )
        );
      }
    }
    handleCloseDialog();
  };

  // Disable handler
  const handleDisableAccount = async(accountData) => {
    const success = await disableAccount(accountData);
    if (success) {
      const updatedAccounts = accounts.filter(
        (account) => account.AccountID !== accountData.accountId
      );
      setAccounts(updatedAccounts);
      setFilteredAccounts(updatedAccounts);
    } else {
      console.error("Failed to disable the account.");
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 4 }}>
          Manage Users
        </Typography>

        <SearchContainer>
          <TextField
            fullWidth
            placeholder="Search by Account ID or Client Name..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{justifyContent:"center"}}
          />
          <Button
            variant="contained"
            onClick={() => handleOpenDialog("create")}
            sx={{ whiteSpace: "nowrap", padding: '1rem'}}
          >
            Create Account
          </Button>
        </SearchContainer>

        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <div>
            {filteredAccounts.length === 0 ? (
              <Typography
                textAlign="center"
                color="textSecondary"
                sx={{ my: 4 }}
              >
                No accounts found
              </Typography>
            ) : (
              filteredAccounts.map((account) => (
                <AccountItem key={account.AccountID}>
                  <div>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Account ID:</strong> {account.accountId} |{" "}
                      <strong>Clients:</strong> {account.clients}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenDialog("edit", account)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDisableAccount(account)}
                    >
                      Disable
                    </Button>
                  </div>
                </AccountItem>
              ))
            )}
          </div>
        )}
      </CardContent>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {dialogMode === "create" ? "Create New Account" : "Edit Account"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="firstName"
            label="First Name"
            fullWidth
            variant="outlined"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="lastName"
            label="Last Name"
            fullWidth
            variant="outlined"
            value={formData.lastName}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="password"
            label="Password"
            fullWidth
            variant="outlined"
            value={formData.password}
            onChange={handleInputChange}
          />
          <TextField
                fullWidth
                select
                label="Role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="agent">Agent</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {dialogMode === "create" ? "Create" : "Save Changes"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Verification Dialog */}
      {showVerificationInput && (
        <Dialog
          open={showVerificationInput}
          onClose={() => setShowVerificationInput(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Verify Your Account</DialogTitle>
          <DialogContent>
            <Typography>
              Please enter the verification code sent to your email to complete the account creation process.
            </Typography>
            <TextField
              margin="dense"
              label="Verification Code"
              fullWidth
              variant="outlined"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowVerificationInput(false)}>Cancel</Button>
            <Button onClick={handleVerificationCodeSubmission} variant="contained" color="primary">
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </StyledCard>
  );
};

export default ManageUsers;
