import React from "react";
import { AppBar, Toolbar, Container, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://crm-dev-alb-1296188767.ap-southeast-1.elb.amazonaws.com';

// Styled button component for navigation with conditional styling for active routes
const NavButton = styled.button.attrs((props) => ({
  className: props.$isActive ? "active" : "",
}))`
  /* Base button styling */
  background: ${(props) =>
    props.$isActive ? "rgba(255, 255, 255, 0.2)" : "transparent"};
  color: white;
  border: none;
  padding: 12px 24px;
  margin: 0 8px;
  border-radius: 4px;
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  transition: all 0.2s; /* Smooth transition for hover effects */

  /* Hover effect */
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

// Container to hold navigation buttons, with spacing and alignment
const NavContainer = styled.div`
  display: flex;
  gap: 1rem; /* Consistent spacing between buttons */
  align-items: center;
  margin-left: auto; /* Pushes navigation to the right */
`;

// Custom styling for the toolbar
const StyledToolbar = styled(Toolbar)`
  && {
    padding: 1rem 2rem;
    justify-content: space-between;
  }
`;

// Main layout component for the application's home screen
// Props:
// - children: The main content to be displayed below the navigation bar
// - userRole: Role of the current user, either 'admin' or 'agent', which determines displayed options
const HomeScreen = ({ children, userRole }) => {
  // Hooks to handle navigation and track the current route
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to check if a given route path is active
  const isActiveRoute = (path) => location.pathname === path;

  const handleLogout = async() => {
    try {
      // Call the sign out API endpoint
      const response = await fetch(`${BASE_URL}/api/auth/users/sign-out`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        }
      });

      if (response.ok) {
        // Clear tokens and user data from local storage
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("ID_TOKEN");
        localStorage.removeItem("REFRESH_TOKEN");
        console.log("Signout successful");
        navigate("/");
      } else {
        console.error("Failed to sign out");
      }
    } catch (error) {
      console.error("Error during signout:", error);
    }
  }

  return (
    <div>
      {/* Conditionally render the navigation bar only if not on the login page */}
      {location.pathname !== "/" && (
        <AppBar position="fixed">
          <StyledToolbar>
            {/* Application title */}
            <Typography variant="h6">CRM System</Typography>

            {/* Container for navigation buttons */}
            <NavContainer>
              {/* Conditional rendering of navigation options based on user role */}
              {userRole === "admin" ? (
                // Admin-specific navigation options
                <>
                  <NavButton
                    $isActive={isActiveRoute("/admin")}
                    onClick={() => navigate("/admin")}
                  >
                    Dashboard
                  </NavButton>
                  <NavButton
                    $isActive={isActiveRoute("/manage-users")}
                    onClick={() => navigate("/manage-users")}
                  >
                    Manage Users
                  </NavButton>
                  <NavButton
                    $isActive={isActiveRoute("/manage-accounts")}
                    onClick={() => navigate("/manage-accounts")}
                  >
                    Manage Accounts
                  </NavButton>
                  <NavButton
                    $isActive={isActiveRoute("/transactions")}
                    onClick={() => navigate("/transactions")}
                  >
                    Transactions
                  </NavButton>
                  
                </>
              ) : (
                // Agent-specific navigation options
                <>
                  <NavButton
                    $isActive={isActiveRoute("/agent")}
                    onClick={() => navigate("/agent")}
                  >
                    Dashboard
                  </NavButton>
                  <NavButton
                    $isActive={isActiveRoute("/create-client")}
                    onClick={() => navigate("/create-client")}
                  >
                    Create Client
                  </NavButton>
                  <NavButton
                    $isActive={isActiveRoute("/manage-clients")}
                    onClick={() => navigate("/manage-clients")}
                  >
                    Manage Clients
                  </NavButton>
                  <NavButton
                    $isActive={isActiveRoute("/agent-transactions")}
                    onClick={() => navigate("/agent-transactions")}
                  >
                    Transactions
                  </NavButton>
                </>
              )}
              {/* Logout button - always visible */}
              <NavButton onClick={handleLogout}>Logout</NavButton>
            </NavContainer>
          </StyledToolbar>
        </AppBar>
      )}

      {/* Main content container with top margin to prevent overlap with fixed header */}
      <Container sx={{ my: 4 }}>{children}</Container>
    </div>
  );
};

export default HomeScreen;
