import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardContent, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { Search } from 'lucide-react';
import styled from 'styled-components';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://crm-dev-alb-1296188767.ap-southeast-1.elb.amazonaws.com';

// Styled components for layout and styling
const StyledCard = styled(Card)`
  max-width: 75rem;
  margin: 7rem auto 0;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  
  .MuiButton-root {
    white-space: nowrap;
  }
`;

const TransactionItem = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  &:last-child {
    border-bottom: none;
  }
`;

// Badge for displaying transaction status with dynamic colors
const StatusBadge = styled.span`
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${props => {
    switch (props.$status.toLowerCase()) {
      case 'completed':
        return '#dcfce7';
      case 'pending':
        return '#fef9c3';
      case 'failed':
        return '#fee2e2';
      default:
        return '#f3f4f6';
    }
  }};
  color: ${props => {
    switch (props.$status.toLowerCase()) {
      case 'completed':
        return '#166534';
      case 'pending':
        return '#854d0e';
      case 'failed':
        return '#991b1b';
      default:
        return '#374151';
    }
  }};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const TransactionView = ({ userRole, agentId }) => {
  // State for managing transaction data and UI
  const [transactions, setTransactions] = useState([]); // Filtered transactions
  const [allTransactions, setAllTransactions] = useState([]); // All fetched transactions
  const [loading, setLoading] = useState(true); // Loading spinner state
  const [searchTerm, setSearchTerm] = useState(''); // Search input value

  // Function to filter transactions based on search term
  const filterTransactions = useCallback((searchValue, transactionData) => {
    if (!searchValue.trim()) {
      return transactionData; // Return all data if no search term
    }

    return transactionData.filter(tx =>
      tx.ID.toLowerCase().includes(searchValue.toLowerCase()) ||
      tx.ClientID.toLowerCase().includes(searchValue.toLowerCase()) ||
      tx.Amount.toString().includes(searchValue) ||
      tx.Status.toLowerCase().includes(searchValue.toLowerCase()) ||
      (tx.Transaction === 'D' ? 'deposit' : 'withdrawal').includes(searchValue.toLowerCase())
    );
  }, []);

  // Fetch and filter transactions on component mount or when role/agent changes
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/sftp/get`, {
          method: 'GET',
        });
  
        if (response.ok) {
          const data = await response.json();
          
          // Apply agent-specific filtering if user is an agent
          let filteredData = userRole === 'agent'
            ? data.filter(tx => tx.AgentID === agentId)
            : data;
  
          setTransactions(filteredData);
          setAllTransactions(filteredData);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchTransactions();
  }, [userRole, agentId]);

  // Apply search filter when searchTerm or allTransactions changes
  useEffect(() => {
    const filtered = filterTransactions(searchTerm, allTransactions);
    setTransactions(filtered);
  }, [searchTerm, allTransactions, filterTransactions]);

  // Helper to format transaction dates for display
  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Helper to format transaction amounts as SGD currency
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-SG', {
      style: 'currency',
      currency: 'SGD'
    }).format(amount);
  };

  // Helper to get descriptive transaction type
  const getTransactionType = (type) => {
    return type === 'D' ? 'Deposit' : 'Withdrawal';
  };

  return (
    <StyledCard>
      <CardContent>
        {/* Header based on user role */}
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 4 }}>
          {userRole === 'admin' ? 'All Transactions' : 'My Client Transactions'}
        </Typography>

        {/* Search bar for filtering transactions */}
        <SearchContainer>
          <TextField
            fullWidth
            placeholder="Search by ID, Client, Amount, Status, or Type..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button
            variant="contained"
            startIcon={<Search size={18} />}
            onClick={() => setSearchTerm(searchTerm)} // Trigger search manually
          >
            Search
          </Button>
        </SearchContainer>

        {/* Conditional rendering for loading, no results, or transaction list */}
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <div>
            {transactions.length === 0 ? (
              <Typography textAlign="center" color="textSecondary" sx={{ my: 4 }}>
                No transactions found
              </Typography>
            ) : (
              // Render each transaction with status, amount, and type
              transactions.map((tx) => (
                <TransactionItem key={tx.ID}>
                  <div>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      <strong>Transaction ID:</strong> {tx.ID}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Client ID:</strong> {tx.ClientID} |{" "}
                      <strong>Type:</strong> {getTransactionType(tx.Transaction)} |{" "}
                      <strong>Date:</strong> {formatTimestamp(tx.Date)}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Typography variant="body1">
                      {formatAmount(tx.Amount)}
                    </Typography>
                    <StatusBadge $status={tx.Status}>
                      {tx.Status}
                    </StatusBadge>
                  </div>
                </TransactionItem>
              ))
            )}
          </div>
        )}
      </CardContent>
    </StyledCard>
  );
};

export default TransactionView;
