import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import styled from 'styled-components';

// Styled component for each activity item in the list
const ActivityItem = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  &:last-child {
    border-bottom: none;
  }
`;

// Styled component for the container holding the list of activities
const ActivityList = styled.div`
  margin-top: 2rem;
`;

// Styled component for the loading spinner container
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://crm-dev-alb-1296188767.ap-southeast-1.elb.amazonaws.com';

const AdminDashboard = () => {
  // State variables to track activities and loading status
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch activities data when the component mounts
  useEffect(() => {
    const fetchActivities = async () => {
      setLoading(true); // Set loading state while fetching data
      try {
        // Fetch data from the API endpoint
        const response = await fetch(`${BASE_URL}/api/logs/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch logs');
        }

        const logs = await response.json();

        // Set the fetched logs to state
        setActivities(logs);
      } catch (error) {
        console.error('Error fetching activities:', error);
        setError('Error fetching logs');
      } finally {
        setLoading(false); // Update loading state once data is fetched or error occurs
      }
    };

    fetchActivities();
  }, []);

  // Format timestamp for display in a readable format
  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Generate descriptive text for each log operation
  const getActionText = (log) => {
    switch (log.operation) {
      case 'CREATE':
        return `created new ${log.attribute_name}`;
      case 'UPDATE':
        return `updated ${log.attribute_name} from "${log.before_value}" to "${log.after_value}"`;
      case 'DELETE':
        return `deleted ${log.attribute_name}`;
      default:
        return log.operation; // Fallback in case of unknown operation
    }
  };

  return (
    <Card sx={{ maxWidth: '75rem', mx: 'auto', mt: 4, marginTop: '7rem' }}>
      <CardContent>
        {/* Dashboard title */}
        <Typography 
          variant="h4" 
          gutterBottom 
          textAlign="center"
          sx={{ fontWeight: 600, mb: 3 }}
        >
          Dashboard
        </Typography>

        {/* Section title for Recent Activities */}
        <Typography 
          variant="h6" 
          color="textSecondary" 
          gutterBottom
          sx={{ ml: 2 }}
        >
          Recent Activities:
        </Typography>

        {/* Display loading spinner while data is being fetched */}
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          // Display list of activities once loading is complete
          <ActivityList>
            {activities.map(log => (
              <ActivityItem key={log.id}>
                {/* Display action text for each activity */}
                <Typography variant="body2">
                  Agent {log.agent_id} {getActionText(log)} for Client {log.client_id}
                </Typography>
                {/* Display formatted timestamp */}
                <Typography 
                  variant="caption" 
                  color="textSecondary"
                  sx={{ ml: 2 }}
                >
                  {formatTimestamp(log.dateTime)}
                </Typography>
              </ActivityItem>
            ))}
          </ActivityList>
        )}
      </CardContent>
    </Card>
  );
};

export default AdminDashboard;
