import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom'; 

// Styled component for individual activity items
const ActivityItem = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5; // Highlight effect on hover
  }

  &:last-child {
    border-bottom: none; // Remove border for the last item
  }
`;

// Styled component for the activity list container
const ActivityList = styled.div`
  margin-top: 2rem; // Add spacing above the list
`;

// Styled component for the loading spinner container
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://crm-dev-alb-1296188767.ap-southeast-1.elb.amazonaws.com';

const AgentDashboard = () => {
  // State to hold fetched activities
  const [activities, setActivities] = useState([]);
  // State to track loading status
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const location = useLocation();
  const agentId = location.state?.agentId;

  // Fetch activities when the component mounts
  useEffect(() => {
    const fetchActivities = async () => {
      setLoading(true); // Set loading state while fetching data
      try {
        // Fetch data from the API endpoint
        const response = await fetch(`${BASE_URL}/api/logs/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch logs');
        }

        const logs = await response.json();

        // Filter logs to include only those with the matching agentId
        const filteredLogs = logs.filter(log => log.agent_id === agentId);
        console.log('Filtered logs:', filteredLogs);

        // Set the filtered logs to state
        setActivities(filteredLogs);
      } catch (error) {
        console.error('Error fetching activities:', error);
        setError('Error fetching logs');
      } finally {
        setLoading(false); // Update loading state once data is fetched or error occurs
      }
    };

    fetchActivities(); // Call the function when component mounts
  }, [agentId]); // Dependency array includes agentId

  // Function to format timestamps into a readable format
  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Function to return action text based on the operation type
  const getActionText = (log) => {
    switch (log.operation) {
      case 'CREATE':
        return `Created new ${log.attributeName}`;
      case 'UPDATE':
        return `Updated ${log.attributeName} from "${log.beforeValue}" to "${log.afterValue}"`;
      case 'DELETE':
        return `Deleted ${log.attributeName}`;
      default:
        return log.operation; // Fallback for unknown operation types
    }
  };

  return (
    <Card sx={{ maxWidth: '75rem', mx: 'auto', mt: 4, marginTop: '7rem' }}>
      <CardContent>
        {/* Dashboard Title */}
        <Typography 
          variant="h4" 
          gutterBottom 
          textAlign="center"
          sx={{ fontWeight: 600, mb: 3 }}
        >
          My Dashboard
        </Typography>

        {/* Section Title for Recent Activities */}
        <Typography 
          variant="h6" 
          color="textSecondary" 
          gutterBottom
          sx={{ ml: 2 }}
        >
          My Recent Activities:
        </Typography>

        {/* Display loading spinner if data is still being fetched */}
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          // Display activity list if data is loaded
          <ActivityList>
            {activities.map(log => (
              <ActivityItem key={log.id}>
                {/* Display action description for each activity */}
                <Typography variant="body2">
                  {getActionText(log)} for Client {log.clientId}
                </Typography>
                {/* Display formatted timestamp */}
                <Typography 
                  variant="caption" 
                  color="textSecondary"
                  sx={{ ml: 2 }}
                >
                  {formatTimestamp(log.dateTime)}
                </Typography>
              </ActivityItem>
            ))}
          </ActivityList>
        )}
      </CardContent>
    </Card>
  );
};

export default AgentDashboard;
