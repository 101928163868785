import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  Box,
  Alert,
} from "@mui/material";

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://crm-dev-alb-1296188767.ap-southeast-1.elb.amazonaws.com';

console.log("Backend URL:", BASE_URL);

const LoginContainer = styled.div`
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const StyledCard = styled(Card)`
  && {
    max-width: 50vh;
    width: 50%;
    margin: 0 auto;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const ContentWrapper = styled(CardContent)`
  && {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-root {
      border-radius: 0.5rem;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    text-transform: none;
    margin-top: 2rem;
  }
`;

const LoginView = ({ onLogin }) => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showVerification, setShowVerification] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordResetConfirmed, setIsPasswordResetConfirmed] = useState(false);

  // Handle input changes for all form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    setMessage('');
    try {
      const usersResponse = await fetch(`${BASE_URL}/api/auth/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
  
      if (!usersResponse.ok) {
        throw new Error('Failed to fetch users');
      }
  
      const users = await usersResponse.json();
      const userData = users["users"];
  
      const user = userData.find(u => u.email === formData.email);
  
      if (!user) {
        setError("User not found");
        setLoading(false);
        return;
      }
  
      const authResponse = await fetch(`${BASE_URL}/api/auth/users/authenticate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email: formData.email, password: formData.password })
      });
  
      const authData = await authResponse.json();
  
      if (authResponse.ok) {
        const role = user.role;
        console.log("Login success for role:", role);

  
        // Call onLogin with role and agentId (if agent)
        onLogin(role, role === 'agent' ? user.user_id : null);
  
        // Navigate based on role
        navigate(role === 'admin' ? '/admin' : '/agent');
      } else {
        setError("Invalid login credentials");
      }
    } catch (error) {
      setError("Error during authentication");
      console.error("Authentication error:", error);
    }
    setLoading(false);
  };


  // Function to handle user verification (MFA)
  const handleUserVerification = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/users/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: formData.email, verificationCode })
      });
      if (response.ok) {
        console.log("Verification successful, logged in.");
        setShowVerification(false);
      } else {
        setMessage('Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying user:', error);
      setMessage('An error occurred during verification.');
    }
  };

  // Function to handle password reset confirmation
  const handlePasswordResetConfirmation = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/users/confirm-password-reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: resetEmail, newPassword })
      });

      if (response.ok) {
        setMessage('Password has been reset. Please enter the verification code sent to your email.');
        setIsPasswordResetConfirmed(true); // Show verification code form
      } else {
        setMessage('Password reset failed. Please try again.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('An error occurred during password reset.');
    }
  };

  return (
    <LoginContainer>
      <StyledCard>
        <ContentWrapper>
          <Typography variant="h4" component="div" align="center">
            CRM Login
          </Typography>

          {!showVerification && !showResetPassword && (
            <FormContainer>
              <StyledTextField
                fullWidth
                label="Email"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <StyledTextField
                fullWidth
                label="Password"
                variant="outlined"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <StyledButton
                variant="contained"
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Login'}
              </StyledButton>
              <Button
                onClick={() => setShowResetPassword(true)}
                sx={{ mt: 2 }}
              >
                Forgot Password?
              </Button>
            </FormContainer>
          )}

          {showResetPassword && !isPasswordResetConfirmed && (
            <FormContainer>
              <StyledTextField
                fullWidth
                label="Email"
                variant="outlined"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
              <StyledTextField
                fullWidth
                label="New Password"
                variant="outlined"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <StyledButton
                variant="contained"
                onClick={handlePasswordResetConfirmation}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Reset Password'}
              </StyledButton>
            </FormContainer>
          )}

          {isPasswordResetConfirmed && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6" align="center" gutterBottom>
                Multi-Factor Authentication
              </Typography>
              <StyledTextField
                label="Verification Code"
                variant="outlined"
                fullWidth
                margin="normal"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
              />
              <StyledButton
                onClick={handleUserVerification}
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 2 }}
              >
                Verify
              </StyledButton>
              {message && (
                <Alert severity="info" sx={{ mt: 2 }}>
                  {message}
                </Alert>
              )}
            </Box>
          )}

          {error && (
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </ContentWrapper>
      </StyledCard>
    </LoginContainer>
  );
};

export default LoginView;