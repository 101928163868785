import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Box,
  Button,
  Stack,
  MenuItem,
} from "@mui/material";
import styled from "styled-components";

// Styled component for the card layout, used to center and style the form container
const StyledCard = styled(Card)`
  max-width: 75rem;
  margin: 7rem auto 0;
`;

const CreateClientProfile = () => {
  // State to manage form data for client profile inputs
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });

  // Handler to update form data based on input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Form submission handler - currently logs data; API integration can be added later
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload on form submission
    try {
      console.log("Form submitted:", formData);
      await fetch('/api/clients/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
    } catch (error) {
      console.error("Error creating client:", error); // Log errors if API call fails
    }
  };

  return (
    <StyledCard sx={{ boxShadow: 3 }}>
      <CardContent>
        {/* Form title */}
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 4 }}>
          Create Client Profile
        </Typography>
        
        {/* Form for entering client information */}
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {/* Name fields (First Name and Last Name) */}
            <Box display="flex" gap={2}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </Box>

            {/* Date of Birth and Gender fields */}
            <Box display="flex" gap={2}>
              <TextField
                fullWidth
                type="date"
                label="Date of Birth"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                sx={{
                  "& .MuiInputLabel-root": {
                    transform: "translate(14px, -9px) scale(0.75)",
                    background: "#fff",
                    padding: "0 8px",
                  },
                }}
              />
              <TextField
                fullWidth
                select
                label="Gender"
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Box>

            {/* Contact information fields (Email and Phone) */}
            <TextField
              fullWidth
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              label="Phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />

            {/* Address field */}
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />

            {/* City and State fields */}
            <Box display="flex" gap={2}>
              <TextField
                fullWidth
                label="City"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                label="State"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </Box>

            {/* Country and Postal Code fields */}
            <Box display="flex" gap={2}>
              <TextField
                fullWidth
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                label="Postal Code"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
              />
            </Box>
          </Stack>

          {/* Action buttons for form submission */}
          <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
            <Button variant="outlined" type="button">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Box>
        </form>
      </CardContent>
    </StyledCard>
  );
};

export default CreateClientProfile;
