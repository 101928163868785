import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    h4: {
      fontWeight: 600,
      letterSpacing: '-0.5px',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            fontFamily: '"Poppins", sans-serif',
          },
          '& input': {
            fontFamily: '"Poppins", sans-serif',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: '0.75rem',
          borderRadius: '8px',
          fontSize: '1rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Poppins", sans-serif',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3b82f6',
      dark: '#2563eb',
    },
  },
});