import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/HomeScreen';
import LoginView from './components/LoginView';
import AdminDashboard from './components/AdminDashboard';
import AgentDashboard from './components/AgentDashboard';
import TransactionView from './components/TransactionView';
import CreateClientProfile from './components/CreateClientProfile';
import ManageUsers from './components/ManageUsers';
import ManageAccounts from './components/ManageAccounts';
import ManageClientProfiles from './components/ManageClientProfiles';
import LogsView from './components/LogsView';

const App = () => {
  // State to track the current user's role and agent ID, if applicable
  const [userRole, setUserRole] = useState(null);
  const [agentId, setAgentId] = useState(null);

  // Function to handle user login, setting role and agent ID if provided
  const handleLogin = (role, id = null) => {
    setUserRole(role);
    setAgentId(id);
  };

  // Helper function to handle route protection based on user role
  const renderProtectedRoute = (role, component) => {
    return userRole === role ? component : <Navigate to="/" />;
  };

  return (
    <Layout userRole={userRole}>
      <Routes>
        {/* Public route: Login page */}
        <Route path="/" element={<LoginView onLogin={handleLogin} />} />

        {/* Protected route: Admin Dashboard, accessible only to admin users */}
        <Route path="/admin" element={renderProtectedRoute('admin', <AdminDashboard />)} />

        {/* Protected route: Agent Dashboard, accessible only to agent users */}
        <Route path="/agent" element={renderProtectedRoute('agent', <AgentDashboard agentId={agentId} />)} />

        {/* Protected route: Transactions for admin users */}
        <Route path="/transactions" element={renderProtectedRoute('admin', <TransactionView userRole={userRole} agentId={agentId} />)} />

        {/* Protected route: Create client profile, accessible only to agent users */}
        <Route path="/create-client" element={renderProtectedRoute('agent', <CreateClientProfile agentId={agentId} />)} />

        {/* Protected route: Manage client profiles, accessible only to agent users */}
        <Route path="/manage-clients" element={renderProtectedRoute('agent', <ManageClientProfiles agentId={agentId} />)} />

        {/* Protected route: Manage users, accessible only to admin users */}
        <Route path="/manage-users" element={renderProtectedRoute('admin', <ManageUsers />)} />

        {/* Protected route: Manage client profiles, accessible only to admin users */}
        <Route path="/manage-accounts" element={renderProtectedRoute('admin', <ManageAccounts />)} />

        {/* Protected route: Agent's transaction view, accessible only to agent users */}
        <Route path="/agent-transactions" element={renderProtectedRoute('agent', <TransactionView userRole={userRole} agentId={agentId} />)} />

        <Route path="/logs" element={<LogsView />} />
      </Routes>
    </Layout>
  );
};

export default App;
