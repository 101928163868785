import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { create } from "@mui/material/styles/createTransitions";

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://crm-dev-alb-1296188767.ap-southeast-1.elb.amazonaws.com';

// Styled Components for layout and styling
const StyledCard = styled(Card)`
  max-width: 75rem;
  margin: 7rem auto 0;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
  
  .MuiButton-root {
    white-space: nowrap;
  }
`;

const ClientItem = styled.div`
  padding: 2rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const GENDER_OPTIONS = ["Male", "Female", "Non-binary", "Prefer not to say"];

const ManageClientProfiles = () => {
  // State variables to manage client data, form inputs, and UI states
  const [clients, setClients] = useState([]); // Holds all client profiles
  const [filteredClients, setFilteredClients] = useState([]); // Holds search-filtered clients
  const [loading, setLoading] = useState(true); // Manages loading spinner visibility
  const [searchTerm, setSearchTerm] = useState(""); // Search input state
  const [openDialog, setOpenDialog] = useState(false); // Dialog visibility state
  const [dialogMode, setDialogMode] = useState('create'); // Determines dialog mode ('create' or 'edit')
  const [selectedClient, setSelectedClient] = useState(null); // Stores currently selected client for editing
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: ""
  });

  // Load client data that is retrieved from the database
  useEffect(() => {
    const fetchClients = async () => {
          try {
            const response = await fetch(`${BASE_URL}/api/clients/`, {
              method: 'GET',
            });

            if (response.ok) {
              const data = await response.json();
              setClients(data);
              setFilteredClients(data);
            }
          } catch (error) {
            console.error("Error fetching clients:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchClients();
      }, []);

  // Updates the search term and filters clients based on input
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterClients(value);
  };

  // Filters clients based on search term
  const filterClients = (value) => {
    const filtered = clients.filter(client =>
      Object.values(client).some(field =>
        String(field).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredClients(filtered);
  };

  // Opens dialog for creating or editing a client profile
  const handleOpenDialog = (mode, client = null) => {
    setDialogMode(mode);
    if (mode === "edit" && client) {
      // Edit mode: populate form with selected client's data
      setSelectedClient(client);
      setFormData(client);
    } else {
      // Create mode: clear form data
      setSelectedClient(null);
      setFormData({
        first_name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        email: "",
        phone_number: "",
        address: "",
        city: "",
        state: "",
        country: "",
        postal_code: ""
      });
    }
    setOpenDialog(true); // Show dialog
  };

  const handleEdit = async () => {
    if (dialogMode === 'edit' && selectedClient) {
      try {
        // Send a PATCH request to update client details
        const response = await fetch(`${BASE_URL}/api/clients/${selectedClient.client_id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
  
        if (response.ok) {
          const updatedClient = await response.json();
          console.log('Client updated successfully:', updatedClient);
  
          // Prepare log data
          const modifiedAttributes = [];
          const beforeValues = [];
          const afterValues = [];
  
          // Identify changes between formData (new values) and selectedClient (old values)
          for (const key in formData) {
            if (formData.hasOwnProperty(key) && selectedClient[key] !== formData[key]) {
              modifiedAttributes.push(key);
              beforeValues.push(selectedClient[key] || '');
              afterValues.push(formData[key] || '');
            }
          }
  
          const logPayload = {
            action_type: "Update",
            attribute_name: modifiedAttributes.join('|'), // Concatenate modified attribute names
            before_value: beforeValues.join('|'), // Concatenate old values
            after_value: afterValues.join('|'), // Concatenate new values
            agent_id: selectedClient.agent_id || '', // Adjust as per your data structure
            client_id: selectedClient.client_id,
          };
  
          // Log the change
          try {
            await fetch(`${BASE_URL}/api/logs/`, {
              method: 'POST',
              headers: {
                'x-api-key': 'P7VpoZ9LSTatWaWC48glm22m6QjcRbwfBrPmHsB7',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(logPayload)
            });
          } catch (logError) {
            console.error("Failed to post to logs API:", logError);
          }
  
          // Update the client in the state
          const updatedClients = clients.map(client =>
            client.client_id === selectedClient.client_id
              ? { ...client, ...formData }
              : client
          );
          setClients(updatedClients);
          setFilteredClients(updatedClients);
        } else {
          const errorData = await response.json();
          console.error('Failed to update client:', errorData.message);
        }
      } catch (error) {
        console.error('Error updating client:', error);
      }
    }
    setOpenDialog(false); // Close the dialog after submission
  };

  // Closes dialog and clears selected client and form data
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedClient(null);
    setFormData({
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      email: "",
      phone_number: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postal_code: ""
    });
  };

  // Updates form data on input field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const createClient = async (clientData, accountId) => {
    try {
      // Generate a unique Client ID (example: prefix with 'CL' and a random number)
      const uniqueClientId = `CL${Date.now()}${Math.floor(Math.random() * 1000)}`;
  
      // Add Client ID and Account ID to the client data
      const payload = {
        ...clientData,
        clientId: uniqueClientId,
        accountId: accountId, // Account ID representing the current user adding the client
      };
  
      const response = await fetch(`${BASE_URL}/api/clients/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Client created successfully:', data);
        try {
          const logPayload = {
            action_type: "Create",
            attribute_name: data.client_id,
            before_value: '',
            after_value: '',
            agent_id: data.agent_id,
            client_id: data.client_id,
          };

          await fetch(`${BASE_URL}/api/logs/`, {
            method: 'POST',
            headers: {
              'x-api-key': 'P7VpoZ9LSTatWaWC48glm22m6QjcRbwfBrPmHsB7',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(logPayload)
          });
        } catch (logError) {
          console.error("Failed to post to logs API:", logError);
        }

        return data;
      } else {
        const errorData = await response.json();
        console.error('Failed to create client:', errorData.message);
      }
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  // Handles form submission for creating or updating a client profile
  const handleSubmit = async() => {
    if (dialogMode === 'create') {
      // Generate new client ID and add to clients list
      const accountId = `AC${Date.now()}${Math.floor(Math.random() * 1000)}`;
      const newClient = await createClient(formData, accountId);

      if (newClient) {
        setClients(prev => [...prev, newClient]);
        setFilteredClients(prev => [...prev, newClient]);
      } else {
        console.error("Failed to create new client");
      }
    } else if (dialogMode === 'edit') {
      await handleEdit();
    }

    // Update existing client profile
    const updatedClients = clients.map(client =>
      client.client_id === selectedClient.client_id
        ? { ...client, ...formData }
        : client
    );
    setClients(updatedClients);
    setFilteredClients(updatedClients);
    handleCloseDialog(); // Close dialog after submission
  };

  // Deletes a client profile from the clients list
  const handleDeleteClient = async (clientData) => {
    try {
      const client_id = clientData.getItem("client_id");
      const response = await fetch(`${BASE_URL}/api/clients/${client_id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`
        }
      });

      if (response.ok) {
        try {
          const logPayload = {
            action_type: "Create",
            attribute_name: clientData.client_id,
            before_value: '',
            after_value: '',
            agent_id: clientData.agent_id,
            client_id: clientData.client_id,
          };

          await fetch(`${BASE_URL}/api/logs/`, {
            method: 'POST',
            headers: {
              'x-api-key': 'P7VpoZ9LSTatWaWC48glm22m6QjcRbwfBrPmHsB7',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(logPayload)
          });
        } catch (logError) {
          console.error("Failed to post to logs API:", logError);
        }

        const updatedClients = clients.filter(client => client.client_id !== client_id);
        setClients(updatedClients);
        setFilteredClients(updatedClients);
      }

      return response.ok;
    } catch (error) {
      console.error('Error deleting account:', error);
      return false;
    }
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 4 }}>
          Manage Client Profiles
        </Typography>

        {/* Search bar for filtering client profiles */}
        <SearchContainer>
          <TextField
            fullWidth
            placeholder="Search clients..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </SearchContainer>

        {/* Loading spinner or filtered client list */}
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <div>
            {/* Display message if no clients found */}
            {filteredClients.length === 0 ? (
              <Typography textAlign="center" color="textSecondary" sx={{ my: 4 }}>
                No clients found
              </Typography>
            ) : (
              // List of filtered clients
              filteredClients.map((client) => (
                <ClientItem key={client.client_id}>
                  <div>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      {/* <strong>Client ID:</strong> {client.client_id} |{" "} */}
                      <strong>Name:</strong> {client.first_name} {client.last_name}
                      <strong>Gender:</strong> {client.gender} |{" "}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Email:</strong> {client.email} |{" "}
                      <strong>Phone:</strong> {client.phone_number} |{" "}
                      {/* <strong>Address:</strong> {client.address}, {client.city}, {client.postalCode} */}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenDialog('edit', selectedClient)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteClient(client.client_id)}
                    >
                      Delete
                    </Button>
                  </div>
                </ClientItem>
              ))
            )}
          </div>
        )}
      </CardContent>

      {/* Dialog for creating or editing a client profile */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {dialogMode === 'create' ? 'Create New Client' : 'Edit Client'}
        </DialogTitle>
        <DialogContent>
          {/* Form fields for client information */}
          <TextField
            margin="dense"
            name="first_name"
            label="First Name"
            fullWidth
            variant="outlined"
            value={formData.first_name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="last_name"
            label="Last Name"
            fullWidth
            variant="outlined"
            value={formData.last_name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="date_of_birth"
            label="Date of Birth"
            type="date"
            fullWidth
            variant="outlined"
            value={formData.date_of_birth}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="dense"
            name="gender"
            select
            label="Gender"
            fullWidth
            variant="outlined"
            value={formData.gender}
            onChange={handleInputChange}
          >
            {GENDER_OPTIONS.map(option => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="phone_number"
            label="Phone Number"
            fullWidth
            variant="outlined"
            value={formData.phone_number}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="address"
            label="Address"
            fullWidth
            variant="outlined"
            value={formData.address}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="city"
            label="City"
            fullWidth
            variant="outlined"
            value={formData.city}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="state"
            label="State"
            fullWidth
            variant="outlined"
            value={formData.state}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="country"
            label="Country"
            fullWidth
            variant="outlined"
            value={formData.country}
            onChange={handleInputChange}
            disabled
          />
          <TextField
            margin="dense"
            name="postal_code"
            label="Postal Code"
            fullWidth
            variant="outlined"
            value={formData.postal_code}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {dialogMode === 'create' ? 'Create' : 'Save Changes'}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
};

export default ManageClientProfiles;
