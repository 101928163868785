import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';

const BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://crm-dev-alb-1296188767.ap-southeast-1.elb.amazonaws.com';

const LogsView = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newLog, setNewLog] = useState({
    action_type: '',
    attribute_name: '',
    before_value: '',
    after_value: '',
    agent_id: '',
    client_id: ''
  });
  const [verificationMessage, setVerificationMessage] = useState('');

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/api/logs/`, {
        headers: {
          'x-api-key': 'P7VpoZ9LSTatWaWC48glm22m6QjcRbwfBrPmHsB7'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setLogs(data);
      } else {
        console.error('Error fetching logs:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateLog = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/logs/`, {
        method: 'POST',
        headers: {
          'x-api-key': 'P7VpoZ9LSTatWaWC48glm22m6QjcRbwfBrPmHsB7',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newLog)
      });
      if (response.ok) {
        setVerificationMessage('Log created successfully.');
        fetchLogs(); // Refresh logs after creation
      } else {
        console.error('Error creating log:', response.statusText);
        setVerificationMessage('Failed to create log.');
      }
    } catch (error) {
      console.error('Error creating log:', error);
      setVerificationMessage('Failed to create log.');
    }
  };

  const handleDeleteLog = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/${id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': 'P7VpoZ9LSTatWaWC48glm22m6QjcRbwfBrPmHsB7'
        }
      });
      if (response.ok) {
        setVerificationMessage('Log deleted successfully.');
        fetchLogs(); // Refresh logs after deletion
      } else {
        console.error('Error deleting log:', response.statusText);
        setVerificationMessage('Failed to delete log.');
      }
    } catch (error) {
      console.error('Error deleting log:', error);
      setVerificationMessage('Failed to delete log.');
    }
  };


  return (
    <div style={{ marginTop: 100 }}>
      <Typography variant="h4" gutterBottom>Logs View</Typography>
      {loading ? (
        <Typography>Loading logs...</Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Action Type</TableCell>
                <TableCell>Attribute Name</TableCell>
                <TableCell>Before Value</TableCell>
                <TableCell>After Value</TableCell>
                <TableCell>Agent ID</TableCell>
                <TableCell>Client ID</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{log.id}</TableCell>
                  <TableCell>{log.action_type}</TableCell>
                  <TableCell>{log.attribute_name}</TableCell>
                  <TableCell>{log.before_value}</TableCell>
                  <TableCell>{log.after_value}</TableCell>
                  <TableCell>{log.agent_id}</TableCell>
                  <TableCell>{log.client_id}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteLog(log.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div style={{ marginTop: 20 }}>
        <Typography variant="h6">Create New Log</Typography>
        <TextField
          label="Action Type"
          variant="outlined"
          value={newLog.action_type}
          onChange={(e) => setNewLog({ ...newLog, action_type: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Attribute Name"
          variant="outlined"
          value={newLog.attribute_name}
          onChange={(e) => setNewLog({ ...newLog, attribute_name: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Before Value"
          variant="outlined"
          value={newLog.before_value}
          onChange={(e) => setNewLog({ ...newLog, before_value: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="After Value"
          variant="outlined"
          value={newLog.after_value}
          onChange={(e) => setNewLog({ ...newLog, after_value: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Agent ID"
          variant="outlined"
          value={newLog.agent_id}
          onChange={(e) => setNewLog({ ...newLog, agent_id: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Client ID"
          variant="outlined"
          value={newLog.client_id}
          onChange={(e) => setNewLog({ ...newLog, client_id: e.target.value })}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateLog}
          style={{ marginTop: 20 }}
        >
          Create Log
        </Button>
      </div>

      {verificationMessage && (
        <Typography color="error" style={{ marginTop: 20 }}>
          {verificationMessage}
        </Typography>
      )}
    </div>
  );
};

export default LogsView;